<template>
  <driveContainer>
    <Nav
      background="transparent"
      style="grid-area: head"
      :color="configTheme.primaryColor"
      :Logo="baseUrl + configTheme.logo"
    />
    <div class="nav-menu" style="grid-area: user">
      <!-- <Gridmenu /> -->
      <User :user="profile" @getToken="getToken" />
      <DialogToken
        @closeDialog="getToken"
        :token="token"
        :dialog="tokenDialog"
      />
    </div>
    <Sidebar style="grid-area: side">
      <DialogComponent @closeDialog="toggledialog" :dialog="dialog" />
      <Newbtn
        @toggledialog="toggledialog"
        :color="configTheme.primaryColor"
        v-bind:themeColor="configTheme.primaryColor"
      />
      <DirectoryTree />
    </Sidebar>
    <FileExplorer
      :searchMode="searchMode"
      :searchResultArray="searchResultArray"
      :approveViewResult="approveResults"
      :shareWithLinkResult="shareWithLinkResults"
    />
    <MetadataPanelstyled
      :metaLoading="metaLoading"
      :metaDatares="metaDatares"
    />
    <!-- <Container> </Container> -->

    <!-- <UploadQueueStyled /> -->
    <UploadProgress
      v-bind:FileName="fileName"
      v-bind:visible="uploadVisible"
      v-bind:value="uploadProgressVal"
      :status="status"
    />
  </driveContainer>
</template>

<script>
import {
  Nav,
  DirectoryTree,
  // Container,
  Sidebar,
  Newbtn,
  FileExplorer,
  // UploadQueueStyled,
  UploadProgress,
  MetadataPanelstyled,
  DialogComponent,
  driveContainer,
} from "../components/styleComponent";
import User from "../components/Navbar/User.vue";
import DialogToken from "../components/Navbar/DialogToken.vue";
import SearchConvertMixins from "../mixins/SearchConvertMixins";
import { mapState } from "vuex";

export default {
  name: "Home",
  mixins: [SearchConvertMixins],
  components: {
    Nav,
    DirectoryTree,
    // Container,
    Sidebar,
    Newbtn,
    FileExplorer,
    // UploadQueueStyled,
    UploadProgress,
    MetadataPanelstyled,
    DialogComponent,
    driveContainer,
    User,
    DialogToken,
  },
  data() {
    return {
      searchMode: false,
      searchResultArray: [],
      approveResults: {},
      shareWithLinkResults: {},
      dialog: false,
      status: "uploading",
      tokenDialog: false,
      uploadVisible: false,
      token: localStorage.getItem("token"),
      baseUrl: process.env.VUE_APP_DOMAIN,
    };
  },
  computed: {
    ...mapState({
      progressVal: (state) => state.uploadProgress,
      fileName: (state) => state.fileNameupload,
      profile: (state) => state.user,
      configTheme: (state) => state.configTheme,
      metaLoading: (state) => state.metaLoading,
      metaDatares: (state) => state.metadata,
    }),
    uploadProgressVal() {
      // if (this.progressVal > 0) {
      //   this.uploadVisible = true;
      // }
      // if (this.progressVal === 100) {
      //   this.status = "success";
      //   setTimeout(() => {
      //     this.$store.dispatch("getDirectory", this.$route.params.id);
      //   }, 2000);
      //   setTimeout(() => {
      //     this.uploadVisible = false;
      //   }, 3000);
      // } else {
      //   this.status = "uploading";
      // }
      return this.progressVal;
    },
  },
  created() {
    this.$store.dispatch("getConfig");
  },
  mounted() {
    if (this.$store.getters.getIsLogin) {
      this.$store.dispatch("getUserProfile");
      this.$store.dispatch("getTag");
    }
    document.title = `${process.env.VUE_APP_LOGOTEXT_1}${process.env.VUE_APP_LOGOTEXT_2}`;
  },
  methods: {
    async searchAction(keyword) {
      console.log("q-1-2", keyword);

      this.searchMode = true;
      const searchResult = await this.$store.dispatch("getSearch", {
        keyword,
      });
      const searchResolve = await this.mapSearchResult(await searchResult.data);
      this.searchResultArray = searchResolve;
      console.log("q-2", searchResolve);
    },
    async approveAction(data) {
      const approveResponse = await this.$store.dispatch(
        "getApproveRetive",
        data
      );
      let approveResolve = [];
      approveResolve.push(await approveResponse.data);
      console.log("approveResolve", approveResolve);
      console.log("data", data);
      if (data.type === "file") {
        this.approveResults = {
          owner: await approveResponse.data.owner.user,
          data: await approveResolve.map((i) => {
            return {
              id: i.id,
              name: i.name,
              type: "file",
              file_type: i.type?.id || null,
              last_modified: i.last_modified,
              owner: i.owner.user,
            };
          }),
        };
      } else if (data.type === "directory") {
        console.log("directory");
        this.approveResults = {
          owner: await approveResponse.data.owner.user,
          data: await approveResolve.map((i) => {
            return {
              id: i.id,
              name: i.name,
              type: i?.type ? i.type.name : "folder",
              file_type: i.type?.id || null,
              last_modified: i.last_modified,
              owner: i.owner.user,
            };
          }),
        };
      } else {
        this.approveResults = {
          owner: await approveResponse.data.owner.user,
          data: await approveResolve.map((i) => {
            return {
              id: i?.directory.id,
              dataset_id: i.id,
              name: i.name,
              type: i?.type ? i.type.name : "dataset",
              file_type: i.type?.id || null,
              last_modified: i?.directory.last_modified || i.last_modified,
              owner: i.owner.user,
            };
          }),
        };
      }
    },
    async shareWithLinkAction(data) {
      const shareResponse = await this.$store.dispatch(
        "getShareWithLink",
        data
      );
      let shareResolve = [];
      shareResolve.push(await shareResponse.data);
      console.log("approveResolve", shareResolve);
      console.log("shareResponse", shareResponse);
      // this.shareWithLinkResults = {
      //   currentPath: [
      //     {
      //       id: 99,
      //       text: "Share with link",
      //       disabled: true,
      //     },
      //   ],
      //   data: shareResolve.map((i) => {
      //     return {
      //       id: i?.directory.id || i.id,
      //       [i.type === "dataset" ? "dataset_id" : null]: i.id,
      //       name: i.name,
      //       type: i.type,
      //       [i.type?.id ? "file_type" : ``]: i.type?.id,
      //       last_modified: i?.directory.last_modified || i.last_modified,
      //       owner: i.owner.user,
      //     };
      //   }),
      // };
      if (shareResponse.data.type === "file") {
        this.shareWithLinkResults = {
          currentPath: [
            {
              id: 99,
              text: "Share with link",
              disabled: true,
            },
          ],
          data: await shareResolve.map((i) => {
            return {
              id: i.id,
              name: i.name,
              type: "file",
              file_type: i.type?.id || null,
              last_modified: i.last_modified,
              owner: i.owner.user,
            };
          }),
        };
      } else if (shareResponse.data.type === "directory") {
        console.log("directory");
        this.shareWithLinkResults = {
          currentPath: [
            {
              id: 99,
              text: "Share with link",
              disabled: true,
            },
          ],
          data: await shareResolve.map((i) => {
            return {
              id: i.id,
              name: i.name,
              type: "folder",
              file_type: i.type?.id || null,
              last_modified: i.last_modified,
              owner: i.owner.user,
            };
          }),
        };
      } else {
        this.shareWithLinkResults = {
          currentPath: [
            {
              id: 99,
              text: "Share with link",
              disabled: true,
            },
          ],
          data: await shareResolve.map((i) => {
            return {
              id: i?.directory.id,
              dataset_id: i.id,
              name: i.name,
              type: i?.type ? i.type : "dataset",
              file_type: i.type?.id || null,
              last_modified: i?.directory.last_modified || i.last_modified,
              owner: i.owner.user,
            };
          }),
        };
      }
    },
    toggledialog(val) {
      console.log("parent", val);
      val === this.dialog ? (this.dialog = !this.dialog) : (this.dialog = val);
    },
    getToken(val) {
      console.log("getTOken", val);
      val === this.tokenDialog
        ? (this.tokenDialog = !this.tokenDialog)
        : (this.tokenDialog = val);
    },
  },
  watch: {
    uploadProgressVal() {
      this.uploadVisible = true;
      // setTimeout(() => {
      //   this.uploadVisible = false;
      // }, 2000);
    },
    $route: {
      handler: function (to, from) {
        console.log("tohome", to);
        console.log("fromhome", from);
        const search = to.query;
        console.log("q-1", search.type);
        if (search.q) {
          // let payload = {
          //   type: search.q ? search.q : "folder",
          //   keyword: search.id ? search.id : 0,
          // };
          this.searchAction(search.q);
        } else if (search.type) {
          console.log("file what");
          let payload = {
            type: search.type,
            id: search.id,
          };
          console.log("payload Approve", payload);
          this.approveAction(payload);
        } else if (search.code) {
          // this.$store.dispatch("get", search.code);
          this.shareWithLinkAction(search.code);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="css" scoped>
.nav-menu {
  display: flex;
  align-items: center;
  padding: 0 1.25em;
  border-bottom: 1px solid #ccc;
}
.app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
